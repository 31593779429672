import { resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";
import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var _IpWhitelistSettings;
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.some.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
var IpWhitelistSettings_1;
import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ref } from "vue";
import { TableV2SortOrder } from "element-plus";
import WTextInput from "@/components/wrapper/w-text-input.vue";
import WLabel from "@/components/wrapper/w-label.vue";
import WStandardButton from "@/components/wrapper/w-standard-button.vue";
import WIcon from "@/components/wrapper/w-icon.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import store from "@/store";
import { ADD_WHITELISTED_IP, IMPORT_WHITELISTED_IPS, REMOVE_ALL_WHITELISTED_IP, REMOVE_WHITELISTED_IP, RESET_WHITELISTED_IPS_SEARCH_PARAMS, SEARCH_WHITELISTED_IPS } from "@/store/actions/ipWhitelist";
import Banner from "@/components/banner/Banner.vue";
import MessagesUtil from "@/utils/messagesUtil";
import { isValidPublicIPV4Address } from "@/utils/util";
import WEmpty from "@/components/wrapper/w-empty.vue";
import { SEARCH_WHITELISTED_IP_PAGE_SIZE } from "@/store/modules/ipWhitelist";
let IpWhitelistSettings = IpWhitelistSettings_1 = (_IpWhitelistSettings = class IpWhitelistSettings extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "platformID", void 0);
    _defineProperty(this, "ipAddressInputValue", '');
    _defineProperty(this, "displayDeleteConfirmationDialog", false);
    _defineProperty(this, "ipToDelete", void 0);
    _defineProperty(this, "deleteDialogTitle", void 0);
    _defineProperty(this, "ipDeleteDialogMessageCustomPath", void 0);
    _defineProperty(this, "uploadInProgress", false);
    _defineProperty(this, "loading", false);
    _defineProperty(this, "currentPage", 0);
    _defineProperty(this, "currentLastVisibleRowIdx", 0);
    _defineProperty(this, "errorMsg", '');
    _defineProperty(this, "upload", ref());
    _defineProperty(this, "sortState", {
      key: IpWhitelistSettings_1.ADDED_ON_KEY,
      order: TableV2SortOrder.ASC
    });
  }
  get whitelistedIpAddress() {
    return store.getters.whitelistedIps;
  }
  get columns() {
    return [{
      key: IpWhitelistSettings_1.IP_ADDRESS_KEY,
      title: `${this.$t('platform_detail.ip_whitelisting_view.ip_tables.columns.whitelisted_ips').toString()} (${this.totalIps})`,
      dataKey: 'ipAddress',
      width: 510
    }, {
      key: IpWhitelistSettings_1.ADDED_ON_KEY,
      title: this.$t('platform_detail.ip_whitelisting_view.ip_tables.columns.added_on').toString(),
      dataKey: 'addedOn',
      width: 510,
      sortable: true
    }, {
      key: 'actions',
      width: 510,
      align: 'right',
      cellRenderer: ({
        rowData
      }) => {
        const onClick = () => {
          this.removeIp(rowData);
        };
        return _createVNode(_resolveComponent("w-icon-button"), {
          "iconClass": "delete-outline",
          "class": "remove-ip-btn",
          "onClick": onClick
        }, null);
      }
    }];
  }
  created() {
    store.dispatch(RESET_WHITELISTED_IPS_SEARCH_PARAMS);
  }
  mounted() {
    this.loadWhitelistedIps();
  }
  get totalIps() {
    return store.getters.totalIps;
  }
  loadWhitelistedIps() {
    this.loading = true;
    store.dispatch(SEARCH_WHITELISTED_IPS, {
      platformID: this.platformID,
      searchIp: this.ipAddressInputValue,
      page: this.currentPage + 1,
      asc: this.sortState.order === TableV2SortOrder.ASC
    }).then(() => {
      this.currentPage++;
    }).catch(err => this.errorMsg = err).finally(() => this.loading = false);
  }
  onInputChange(value) {
    this.ipAddressInputValue = value?.trim();
    this.reloadWhitelistedIps();
  }
  reloadWhitelistedIps() {
    this.currentPage = 0;
    store.dispatch(RESET_WHITELISTED_IPS_SEARCH_PARAMS);
    this.loadWhitelistedIps();
  }
  onSort({
    key,
    order
  }) {
    this.sortState = {
      key: key,
      order: order
    };
    this.reloadWhitelistedIps();
  }
  onRowRendered($event) {
    if (this.currentLastVisibleRowIdx === $event.rowVisibleEnd) {
      return;
    } else {
      this.currentLastVisibleRowIdx = $event.rowVisibleEnd;
    }
    if (this.shouldSilentlyLoadMoreData(this.currentLastVisibleRowIdx)) {
      if (this.whitelistedIpAddress.length < this.totalIps) {
        this.loadWhitelistedIps();
      }
    }
  }
  shouldSilentlyLoadMoreData(currentLastVisibleRowIdx) {
    return this.whitelistedIpAddress.length - currentLastVisibleRowIdx < IpWhitelistSettings_1.NB_ROW_BEFORE_REFRESH && !this.loading;
  }
  addNewIpAddress() {
    if (this.doesMatchRequirements(this.ipAddressInputValue)) {
      this.loading = true;
      store.dispatch(ADD_WHITELISTED_IP, {
        ipAddress: this.ipAddressInputValue,
        platformID: this.platformID
      }).then(() => {
        this.ipAddressInputValue = '';
        MessagesUtil.showSuccessMessage(this.$t('platform_detail.ip_whitelisting_view.toast_msg.ip_add_success').toString());
        this.reloadWhitelistedIps();
        this.errorMsg = '';
      }).catch(err => {
        this.errorMsg = err;
      }).finally(() => this.loading = false);
    }
  }
  downloadAllIpAddresses() {
    if (this.whitelistedIpAddress.length > 0) {
      const csvData = this.whitelistedIpAddress.map(({
        ipAddress
      }) => ipAddress);
      const csvContent = csvData.join('\n');
      const blob = new Blob([csvContent], {
        type: 'text/csv;charset=utf-8;'
      });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'whitelisted_ip_addresses-' + this.platformID + '.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  deleteAllIpsAddress() {
    this.ipToDelete = null;
    this.deleteDialogTitle = this.$t('platform_detail.ip_whitelisting_view.all_ip_delete_dialog.title');
    this.ipDeleteDialogMessageCustomPath = 'all_ip_delete_dialog';
    this.displayDeleteConfirmationDialog = true;
  }
  onFileSelected(fileData) {
    const file = fileData.raw;
    const reader = new FileReader();
    reader.onloadend = this.batchImportIpsAddress;
    reader.readAsDataURL(file);
  }
  batchImportIpsAddress(e) {
    const fileUrl = e.target.result;
    this.uploadInProgress = true;
    store.dispatch(IMPORT_WHITELISTED_IPS, {
      fileUrl: fileUrl,
      platformID: this.platformID
    }).then(() => {
      MessagesUtil.showSuccessMessage(this.$t('platform_detail.ip_whitelisting_view.toast_msg.ip_batch_add_success').toString());
      this.reloadWhitelistedIps();
      this.errorMsg = '';
    }).catch(err => {
      this.errorMsg = err;
    }).finally(() => this.uploadInProgress = false);
  }
  removeIp(whitelistedIp) {
    this.ipToDelete = whitelistedIp;
    this.deleteDialogTitle = this.$t('platform_detail.ip_whitelisting_view.single_ip_delete_dialog.title');
    this.ipDeleteDialogMessageCustomPath = 'single_ip_delete_dialog';
    this.displayDeleteConfirmationDialog = true;
  }
  doConfirmDelete() {
    this.loading = true;
    if (this.ipToDelete) {
      store.dispatch(REMOVE_WHITELISTED_IP, {
        ipId: this.ipToDelete.id,
        platformID: this.platformID
      }).then(() => {
        MessagesUtil.showSuccessMessage(this.$t('platform_detail.ip_whitelisting_view.toast_msg.ip_delete_success').toString());
        this.errorMsg = '';
        if (this.whitelistedIpAddress.length < SEARCH_WHITELISTED_IP_PAGE_SIZE && this.whitelistedIpAddress.length < this.totalIps) {
          this.loadWhitelistedIps();
        }
      }).catch(err => {
        this.errorMsg = err;
      }).finally(() => this.loading = false);
    } else {
      store.dispatch(REMOVE_ALL_WHITELISTED_IP, this.platformID).then(() => {
        MessagesUtil.showSuccessMessage(this.$t('platform_detail.ip_whitelisting_view.toast_msg.ip_delete_all_success').toString());
        this.errorMsg = '';
      }).catch(err => {
        this.errorMsg = err;
      }).finally(() => this.loading = false);
    }
  }
  doCancelDelete() {
    this.displayDeleteConfirmationDialog = false;
    this.ipToDelete = null;
    this.deleteDialogTitle = '';
    this.ipDeleteDialogMessageCustomPath = '';
  }
  doesMatchRequirements(ipAddres) {
    return this.isNotWhitelisted(ipAddres) && this.isValidIpAddress(ipAddres);
  }
  isValidIpAddress(ipAddress) {
    if (!isValidPublicIPV4Address(ipAddress)) {
      MessagesUtil.showErrorMessage(this.$t('platform_detail.ip_whitelisting_view.toast_msg.ip_invalid_error').toString());
      return false;
    }
    return true;
  }
  isNotWhitelisted(ipAddress) {
    if (this.whitelistedIpAddress.some(w => w.ipAddress === ipAddress)) {
      MessagesUtil.showErrorMessage(this.$t('platform_detail.ip_whitelisting_view.toast_msg.ip_already_whitelisted_error').toString());
      return false;
    }
    return true;
  }
}, _defineProperty(_IpWhitelistSettings, "IP_ADDRESS_KEY", 'IP_ADDRESS'), _defineProperty(_IpWhitelistSettings, "ADDED_ON_KEY", 'ADDED_ON_KEY'), _defineProperty(_IpWhitelistSettings, "NB_ROW_BEFORE_REFRESH", 8), _IpWhitelistSettings);
__decorate([Prop()], IpWhitelistSettings.prototype, "platformID", void 0);
IpWhitelistSettings = IpWhitelistSettings_1 = __decorate([Options({
  components: {
    WEmpty,
    Banner,
    ConfirmDialog,
    WIcon,
    WStandardButton,
    WLabel,
    WTextInput
  }
})], IpWhitelistSettings);
export default IpWhitelistSettings;