import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import HeaderTable from '@/components/platform/copySpeach/HeaderTable.vue';
import TitleCell from '@/components/platform/copySpeach/TitleCell.vue';
import store from '@/store';
import { SET_AUTHOR, SET_CATEGORY } from '@/store/actions/speach';
import SearchUser from '@/components/platform/copySpeach/SearchUser.vue';
import SelectCategory from '@/components/platform/copySpeach/SelectCategory.vue';
let SpeachConfigureTable = class SpeachConfigureTable extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "destinationPlatform", void 0);
    _defineProperty(this, "listSpeach", void 0);
    _defineProperty(this, "tableRef", 'speach-configure-table');
    _defineProperty(this, "checkedRows", []);
    _defineProperty(this, "numberOfSpeachesChecked", 0);
    _defineProperty(this, "authorActionBar", null);
    _defineProperty(this, "categoryActionBar", null);
  }
  mounted() {}
  // -- CUSTOM FUNCTIONS
  updateCheckedRows(checkedList) {
    this.numberOfSpeachesChecked = checkedList.length;
    this.checkedRows = checkedList;
  }
  changeAuthor(userCopy, cour) {
    store.dispatch(SET_AUTHOR, {
      cour,
      userCopy
    });
    // We need to force refresh of the checkbox context when we update the author in the speachs list in the store.
    this.refreshCheckedRowsState();
  }
  changeCategory(category, cour) {
    store.dispatch(SET_CATEGORY, {
      cour,
      category
    });
    // We need to force refresh of the checkbox context when we update the category in the speachs list in the store.
    this.refreshCheckedRowsState();
  }
  refreshCheckedRowsState() {
    let speachTableRef = this.$refs[this.tableRef];
    this.$nextTick(() => {
      this.checkedRows?.forEach(cr => {
        speachTableRef.toggleRowSelection(cr, true);
      });
    });
  }
  changeAuthorActionBar(userCopy) {
    this.authorActionBar = userCopy;
  }
  changeCategoryActionBar(category) {
    this.categoryActionBar = category;
  }
  visibilityChanged(isVisible, row) {
    if (isVisible && !row?.title) {
      this.$emit('empty-cour-visible', row);
    }
  }
  cancelActionBar() {
    this.resetCheckedSpeach();
  }
  confirmActionBar() {
    for (let cour of this.checkedRows) {
      if (this.authorActionBar) {
        store.dispatch(SET_AUTHOR, {
          cour,
          userCopy: this.authorActionBar
        });
      }
      if (this.categoryActionBar) {
        store.dispatch(SET_CATEGORY, {
          cour,
          category: this.categoryActionBar
        });
      }
    }
    this.resetCheckedSpeach();
  }
  resetCheckedSpeach() {
    this.authorActionBar = null;
    this.categoryActionBar = null;
    this.checkedRows = [];
    this.numberOfSpeachesChecked = 0;
    let speachTableRef = this.$refs[this.tableRef];
    speachTableRef?.clearSelection();
  }
};
__decorate([Prop({
  default: ''
})], SpeachConfigureTable.prototype, "destinationPlatform", void 0);
__decorate([Prop({
  default: []
})], SpeachConfigureTable.prototype, "listSpeach", void 0);
SpeachConfigureTable = __decorate([Options({
  components: {
    SelectCategory,
    SearchUser,
    HeaderTable,
    TitleCell
  }
})], SpeachConfigureTable);
export default SpeachConfigureTable;