import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import debounce from 'lodash/debounce';
import { CHECK_ALL_SPEACH_FROM_BACK, CHECK_ALL_SPEACH_NOT_CHECKED, CHECK_SPEACH, COPY_SPEACH_SEARCH_FILTERS, EMPTY_SPEACH_LIST, GET_SPEACH_FROM_SPEACH_ID, REFRESH_CHECKED_SPEACH_TO_SHOW, REFRESH_COUR_IN_CHECKED_LIST, SPEACH_IDS_LIST, SPEACH_LIST, UNCHECK_ALL_SPEACH_FROM_BACK, UNCHECK_ALL_SPEACH_IN_SEARCH_LIST, UNCHECK_SPEACH } from '@/store/actions/speach';
import { v4 as uuidv4 } from 'uuid';
import SpeachTable from '@/components/platform/copySpeach/SpeachTable.vue';
import InfiniteLoading from 'vue-infinite-loading';
import Banner from "@/components/banner/Banner.vue";
let ChooseSpeach = class ChooseSpeach extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "checkAllMessage", false);
    _defineProperty(this, "uncheckAllMessage", false);
    _defineProperty(this, "infiniteLoaderID", uuidv4());
    _defineProperty(this, "loading", false);
    _defineProperty(this, "debounceInfinite", debounce(state => {
      if (this.totalNumber <= this.listSpeach.length) {
        state.complete();
        state.loaded();
      } else {
        this.infiniteHandler(state);
      }
    }, 250));
  }
  // -- Computed variables
  get listSpeach() {
    return store.getters.listSpeach;
  }
  get listCheckedSpeach() {
    return store.getters.listCheckedSpeach;
  }
  get listCheckedSpeachSearchList() {
    return store.getters.listCheckedSpeachSearchList;
  }
  get currentPage() {
    return store.getters.currentPageSpeach;
  }
  get totalNumber() {
    return store.getters.totalNumberSpeach;
  }
  get sourcePlatform() {
    return store.getters.sourcePlatform;
  }
  get sourceCategories() {
    return store.getters.categories(this.sourcePlatform.platformID);
  }
  get searchFilters() {
    return store.getters.copySpeachSearchFilters;
  }
  mounted() {
    if (!this.listSpeach?.length) {
      this.loading = true;
      this.searchSpeach().then(() => this.loading = false);
    }
  }
  async filterTitle(titleFilter) {
    await this.filter(titleFilter, this.searchFilters?.author, this.searchFilters?.category);
  }
  async filterAuthor(authorFilter) {
    await this.filter(this.searchFilters?.title, authorFilter, this.searchFilters?.category);
  }
  async filterCategory(categoryFilter) {
    const categoryFilterId = categoryFilter?.id;
    const category = this.sourceCategories?.find(c => c.id === categoryFilterId);
    await this.filter(this.searchFilters?.title, this.searchFilters?.author, category);
  }
  async filter(filteredTitle, filteredAuthor, filteredCategory) {
    await store.dispatch(COPY_SPEACH_SEARCH_FILTERS, {
      title: filteredTitle,
      author: filteredAuthor,
      category: filteredCategory
    });
    await this.searchSpeach();
  }
  async check(checkedList, row) {
    if (row) {
      this.checkAllMessage = false;
      this.uncheckAllMessage = false;
      const indexOfRow = checkedList.findIndex(c => c.speachID === row.speachID);
      if (indexOfRow != -1) {
        await store.dispatch(CHECK_SPEACH, row);
      } else {
        await store.dispatch(UNCHECK_SPEACH, row);
      }
      await store.dispatch(REFRESH_CHECKED_SPEACH_TO_SHOW);
    }
  }
  async allCheck(checkedList) {
    this.uncheckAllMessage = false;
    if (checkedList?.length > 0) {
      await store.dispatch(CHECK_ALL_SPEACH_NOT_CHECKED, checkedList);
      this.checkAllMessage = true;
    } else {
      this.checkAllMessage = false;
      await store.dispatch(UNCHECK_ALL_SPEACH_IN_SEARCH_LIST);
    }
    await store.dispatch(REFRESH_CHECKED_SPEACH_TO_SHOW);
  }
  async selectAllFromBack() {
    this.checkAllMessage = false;
    this.uncheckAllMessage = true;
    await store.dispatch(SPEACH_IDS_LIST, {
      platformID: this.sourcePlatform.platformID,
      title: this.searchFilters?.title,
      author: this.searchFilters?.author,
      categoryId: this.searchFilters?.category?.id
    });
    await store.dispatch(CHECK_ALL_SPEACH_FROM_BACK);
  }
  async clearAllFromBack() {
    this.checkAllMessage = false;
    this.uncheckAllMessage = false;
    await store.dispatch(UNCHECK_ALL_SPEACH_FROM_BACK);
  }
  async checkFromSelectedList(checkedList, row) {
    await this.check(checkedList, row);
    await store.dispatch(REFRESH_CHECKED_SPEACH_TO_SHOW);
  }
  async refreshCourAndAddToListChecked(row) {
    store.dispatch(GET_SPEACH_FROM_SPEACH_ID, {
      platformID: this.sourcePlatform.platformID,
      speachID: row.speachID
    }).then(cour => {
      if (cour) {
        store.dispatch(REFRESH_COUR_IN_CHECKED_LIST, cour);
      }
    });
  }
  async searchSpeach(page = 1, append = false) {
    this.checkAllMessage = false;
    this.uncheckAllMessage = false;
    if (this.sourcePlatform) {
      await store.dispatch(SPEACH_LIST, {
        platformID: this.sourcePlatform.platformID,
        title: this.searchFilters?.title,
        author: this.searchFilters?.author,
        categoryId: this.searchFilters?.category?.id,
        page,
        append
      });
    } else {
      await store.dispatch(EMPTY_SPEACH_LIST);
    }
    //if we don't append the search, it is a new one, so we have to reset the infinite handler
    if (!append) {
      this.resetInfiniteHandler();
    }
    await store.dispatch(REFRESH_CHECKED_SPEACH_TO_SHOW);
  }
  //We need to reset the infinite handler when we perform a new search.
  resetInfiniteHandler() {
    //The easiest way to reset the infinite handler is to change its id
    this.infiniteLoaderID = uuidv4();
  }
  infiniteHandler(state) {
    this.searchSpeach(this.currentPage + 1, true).then(() => {
      if (this.totalNumber <= this.listSpeach.length) {
        state.complete();
      }
      state.loaded();
    }).catch(() => {
      state.complete();
    });
  }
  goToPlatform(url) {
    window.open(url, '_blank');
  }
};
ChooseSpeach = __decorate([Options({
  components: {
    SpeachTable,
    InfiniteLoading,
    Banner: Banner
  }
})], ChooseSpeach);
export default ChooseSpeach;