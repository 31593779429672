import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c2e7b9d8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "actions-row"
};
const _hoisted_2 = {
  class: "actions-row__ip-input-block"
};
const _hoisted_3 = {
  class: "ip-address-input-group"
};
const _hoisted_4 = {
  class: "actions-row__ip-actions-block"
};
const _hoisted_5 = {
  class: "ips-table-row"
};
const _hoisted_6 = {
  class: "ips-table-row__table-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_banner = _resolveComponent("banner");
  const _component_w_label = _resolveComponent("w-label");
  const _component_w_text_input = _resolveComponent("w-text-input");
  const _component_w_standard_button = _resolveComponent("w-standard-button");
  const _component_w_icon = _resolveComponent("w-icon");
  const _component_w_tooltip = _resolveComponent("w-tooltip");
  const _component_el_upload = _resolveComponent("el-upload");
  const _component_w_empty = _resolveComponent("w-empty");
  const _component_el_table_v2 = _resolveComponent("el-table-v2");
  const _component_el_auto_resizer = _resolveComponent("el-auto-resizer");
  const _component_i18n_t = _resolveComponent("i18n-t");
  const _component_confirm_dialog = _resolveComponent("confirm-dialog");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_banner, {
    type: "info"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('platform_detail.ip_whitelisting_view.general_info').toString()), 1)]),
    _: 1
  }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_w_label, {
    class: "ip-address-input-label"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('platform_detail.ip_whitelisting_view.add_ip_input.label').toString()), 1)]),
    _: 1
  }), _createElementVNode("div", _hoisted_3, [_createVNode(_component_w_text_input, {
    placeholder: _ctx.$t('platform_detail.ip_whitelisting_view.add_ip_input.placeholder').toString(),
    value: _ctx.ipAddressInputValue,
    width: "318px",
    customClass: "ip-address-input",
    onInputChanged: _ctx.onInputChange
  }, null, 8, ["placeholder", "value", "onInputChanged"]), _createVNode(_component_w_standard_button, {
    class: "add-new-ip-btn",
    type: "primary",
    minWidth: "80px",
    minHeight: "32.5px",
    disabled: !_ctx.ipAddressInputValue,
    onClick: _cache[0] || (_cache[0] = $event => _ctx.addNewIpAddress())
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('platform_detail.ip_whitelisting_view.add_ip_input.button_label').toString()), 1)]),
    _: 1
  }, 8, ["disabled"])])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_upload, {
    ref: "upload",
    "show-file-list": false,
    accept: ".csv",
    "on-change": _ctx.onFileSelected,
    "auto-upload": false,
    disabled: _ctx.uploadInProgress
  }, {
    default: _withCtx(() => [_createVNode(_component_w_tooltip, {
      content: _ctx.$t('platform_detail.ip_whitelisting_view.import_ips_button_tooltip').toString(),
      placement: "top"
    }, {
      default: _withCtx(() => [_createVNode(_component_w_standard_button, {
        class: "import-ips-addresses-btn",
        type: "default",
        minWidth: "236px",
        loading: _ctx.uploadInProgress,
        disabled: _ctx.uploadInProgress
      }, {
        default: _withCtx(() => [!_ctx.uploadInProgress ? (_openBlock(), _createBlock(_component_w_icon, {
          key: 0,
          icon: "upload-outline"
        })) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(_ctx.$t('platform_detail.ip_whitelisting_view.import_ips_button_label').toString()), 1)]),
        _: 1
      }, 8, ["loading", "disabled"])]),
      _: 1
    }, 8, ["content"])]),
    _: 1
  }, 8, ["on-change", "disabled"]), _createElementVNode("div", null, [_createVNode(_component_w_standard_button, {
    type: "default",
    minWidth: "158px",
    disabled: !_ctx.whitelistedIpAddress?.length,
    onClick: _cache[1] || (_cache[1] = $event => _ctx.downloadAllIpAddresses())
  }, {
    default: _withCtx(() => [_createVNode(_component_w_icon, {
      icon: "download-outline"
    }), _createTextVNode(" " + _toDisplayString(_ctx.$t('platform_detail.ip_whitelisting_view.download_all_ip_button_label').toString()), 1)]),
    _: 1
  }, 8, ["disabled"])]), _createVNode(_component_w_standard_button, {
    class: "delete-all-ips-btn",
    type: "danger",
    minWidth: "158px",
    disabled: !_ctx.whitelistedIpAddress?.length,
    onClick: _cache[2] || (_cache[2] = $event => _ctx.deleteAllIpsAddress())
  }, {
    default: _withCtx(() => [_createVNode(_component_w_icon, {
      icon: "delete-outline"
    }), _createTextVNode(" " + _toDisplayString(_ctx.$t('platform_detail.ip_whitelisting_view.delete_all_ip_button_label').toString()), 1)]),
    _: 1
  }, 8, ["disabled"])])]), _createElementVNode("div", _hoisted_5, [!!_ctx.errorMsg ? (_openBlock(), _createBlock(_component_banner, {
    key: 0,
    type: "error"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.errorMsg), 1)]),
    _: 1
  })) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_auto_resizer, null, {
    default: _withCtx(({
      height,
      width
    }) => [_withDirectives((_openBlock(), _createBlock(_component_el_table_v2, {
      data: _ctx.whitelistedIpAddress,
      height: height,
      width: width,
      columns: _ctx.columns,
      "sort-by": _ctx.sortState,
      onColumnSort: _ctx.onSort,
      onRowsRendered: _ctx.onRowRendered,
      "scrollbar-always-on": true
    }, {
      empty: _withCtx(() => [_createVNode(_component_w_empty, null, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('platform_detail.ip_whitelisting_view.ip_tables.no_data').toString()), 1)]),
        _: 1
      })]),
      _: 2
    }, 1032, ["data", "height", "width", "columns", "sort-by", "onColumnSort", "onRowsRendered"])), [[_directive_loading, _ctx.loading && !_ctx.totalIps]])]),
    _: 1
  })])]), _ctx.displayDeleteConfirmationDialog ? (_openBlock(), _createBlock(_component_confirm_dialog, {
    key: 0,
    title: _ctx.deleteDialogTitle,
    visible: _ctx.displayDeleteConfirmationDialog,
    "is-delete-action": true,
    "confirm-btn-title": 'delete',
    onDialogClose: _ctx.doCancelDelete,
    onOnConfirmed: _cache[3] || (_cache[3] = $event => _ctx.doConfirmDelete())
  }, {
    default: _withCtx(() => [_createVNode(_component_i18n_t, {
      scope: "global",
      keypath: `platform_detail.ip_whitelisting_view.${_ctx.ipDeleteDialogMessageCustomPath}.message.value`,
      tag: "div"
    }, {
      delete: _withCtx(() => [_createElementVNode("b", null, _toDisplayString(_ctx.$t(`platform_detail.ip_whitelisting_view.${_ctx.ipDeleteDialogMessageCustomPath}.message.delete`).toString()), 1)]),
      ipAddress: _withCtx(() => [_createElementVNode("b", null, _toDisplayString(_ctx.ipToDelete?.ipAddress), 1)]),
      _: 1
    }, 8, ["keypath"])]),
    _: 1
  }, 8, ["title", "visible", "onDialogClose"])) : _createCommentVNode("", true)], 64);
}