import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import store from '@/store';
import { COPY_SPEACH } from '@/store/actions/speach';
import { CopySpeachStatus } from '@/store/enums/speachEnum';
import SpeachResultTable from '@/components/platform/copySpeach/SpeachResultTable.vue';
import Banner from '@/components/banner/Banner.vue';
let ResultCopySpeach = class ResultCopySpeach extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "loading", true);
    _defineProperty(this, "speachCopied", 0);
  }
  mounted() {
    this.launchCopy();
  }
  async launchCopy() {
    this.loading = true;
    this.speachCopied = 0;
    for (let c of this.listSpeachToCopy) {
      await store.dispatch(COPY_SPEACH, {
        platformSourceID: this.sourcePlatform?.platformID,
        platformDestinationID: this.destinationPlatform?.platformID,
        courToCopy: c
      });
      this.speachCopied++;
    }
    this.loading = false;
    this.$emit('speachesCopied');
  }
  get listSpeachToCopy() {
    return store.getters.listSpeachToCopy;
  }
  get totalSpeachCopiedSuccess() {
    return this.listSpeachToCopy.filter(x => CopySpeachStatus.SUCCESS === x.copySpeachStatus).length;
  }
  get totalSpeachCopiedError() {
    return this.listSpeachToCopy.filter(x => CopySpeachStatus.ERROR === x.copySpeachStatus).length;
  }
  get sourcePlatform() {
    return store.getters.sourcePlatform;
  }
  get destinationPlatform() {
    return store.getters.destinationPlatform;
  }
};
ResultCopySpeach = __decorate([Options({
  components: {
    SpeachResultTable,
    Banner: Banner
  }
})], ResultCopySpeach);
export default ResultCopySpeach;