import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import HeaderTable from '@/components/platform/copySpeach/HeaderTable.vue';
import TitleCell from '@/components/platform/copySpeach/TitleCell.vue';
import UserCell from '@/components/platform/copySpeach/UserCell.vue';
import Cell from '@/components/platform/copySpeach/Cell.vue';
import SelectCategory from '@/components/platform/copySpeach/SelectCategory.vue';
let SpeachTable = class SpeachTable extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "tableId", void 0);
    _defineProperty(this, "listSpeach", void 0);
    _defineProperty(this, "checkedSpeachIds", void 0);
    _defineProperty(this, "searchable", void 0);
    _defineProperty(this, "loading", void 0);
    _defineProperty(this, "platformId", void 0);
    _defineProperty(this, "headerCheckable", void 0);
    _defineProperty(this, "checkAllMessage", void 0);
    _defineProperty(this, "uncheckAllMessage", void 0);
    _defineProperty(this, "totalOfSpeachesSearch", void 0);
    _defineProperty(this, "numberOfSpeachesChecked", 0);
    _defineProperty(this, "tableData", []);
    _defineProperty(this, "titleFilter", '');
    _defineProperty(this, "authorFilter", '');
    _defineProperty(this, "categoryFilter", null);
  }
  mounted() {
    this.onListSpeachChanged();
    this.updateTableRowsChecksState();
  }
  onListSpeachChanged() {
    // We track listSpeach input then update table data (keeping array reference). this avoid el-table to rebuild whole data view on input changes.
    this.tableData.length = 0;
    this.tableData.push(...this.listSpeach);
  }
  onCheckedSpeachChanged() {
    this.updateTableRowsChecksState();
  }
  onCategoryUpdated(category) {
    this.categoryFilter = category;
    this.$emit('filterCategory', this.categoryFilter);
  }
  onTitleFilterUpdated(title) {
    this.titleFilter = title;
    this.$emit('filterTitle', this.titleFilter);
  }
  onAuthorFilterUpdated(author) {
    this.authorFilter = author;
    this.$emit('filterAuthor', this.authorFilter);
  }
  rowChecked(checkedList, row) {
    this.numberOfSpeachesChecked = checkedList.length;
    this.$emit('check', checkedList, row);
  }
  allChecked(checkedList) {
    this.numberOfSpeachesChecked = checkedList.length;
    this.$emit('all-check', checkedList);
  }
  selectAllFromBack() {
    this.$emit('select-all-from-back');
  }
  clearAllFromBack() {
    this.$emit('clear-all-from-back');
  }
  visibilityChanged(isVisible, row) {
    if (isVisible && !row?.title) {
      this.$emit('empty-cour-visible', row);
    }
  }
  updateTableRowsChecksState() {
    let speachTableRef = this.$refs[this.tableId];
    if (speachTableRef) {
      if (this.checkedSpeachIds?.length) {
        this.tableData.forEach(row => {
          if (this.checkedSpeachIds.includes(row.speachID)) {
            speachTableRef.toggleRowSelection(row, true);
          } else {
            speachTableRef.toggleRowSelection(row, false);
          }
        });
      } else {
        speachTableRef.clearSelection();
      }
    }
  }
};
__decorate([Prop({
  required: true
})], SpeachTable.prototype, "tableId", void 0);
__decorate([Prop({
  default: []
})], SpeachTable.prototype, "listSpeach", void 0);
__decorate([Prop({
  default() {
    return [];
  }
})], SpeachTable.prototype, "checkedSpeachIds", void 0);
__decorate([Prop({
  default: false,
  type: Boolean
})], SpeachTable.prototype, "searchable", void 0);
__decorate([Prop({
  default: false
})], SpeachTable.prototype, "loading", void 0);
__decorate([Prop({
  default: ''
})], SpeachTable.prototype, "platformId", void 0);
__decorate([Prop({
  default: false
})], SpeachTable.prototype, "headerCheckable", void 0);
__decorate([Prop({
  default: false
})], SpeachTable.prototype, "checkAllMessage", void 0);
__decorate([Prop({
  default: false
})], SpeachTable.prototype, "uncheckAllMessage", void 0);
__decorate([Prop({
  default: 0
})], SpeachTable.prototype, "totalOfSpeachesSearch", void 0);
__decorate([Watch('listSpeach')], SpeachTable.prototype, "onListSpeachChanged", null);
__decorate([Watch('checkedSpeachIds')], SpeachTable.prototype, "onCheckedSpeachChanged", null);
SpeachTable = __decorate([Options({
  components: {
    SelectCategory,
    UserCell,
    Cell,
    TitleCell,
    HeaderTable
  },
  emits: ['filterTitle', 'filterAuthor', 'filterCategory', 'check', 'all-check', 'select-all-from-back', 'clear-all-from-back', 'empty-cour-visible']
})], SpeachTable);
export default SpeachTable;