import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import store from '@/store';
import { MENU_VISIBLE } from '@/store/actions/configurations';
import { isAuth, logoutUser } from "@/services/auth";
import WDropdown from "@/components/wrapper/sort-dropdown.vue";
import i18n, { DEFAULT_LOCALE, switchLanguageAsync } from "@/i18n";
import { LangsEnum } from '@/i18n/langsEnum';
let Header = class Header extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "logo", void 0);
    _defineProperty(this, "languagesDropdownItems", []);
  }
  mounted() {
    this.languagesDropdownItems = [{
      key: LangsEnum.EN,
      icon: LangsEnum.EN
    }, {
      key: LangsEnum.FR,
      icon: LangsEnum.FR
    }, {
      key: LangsEnum.DE,
      icon: LangsEnum.DE
    }, {
      key: LangsEnum.ES,
      icon: LangsEnum.ES
    }];
    const userLang = i18n.global.locale || DEFAULT_LOCALE;
    this.languagesDropdownItems.forEach(item => item.isSelected = item.key === userLang);
  }
  onLangChanges(key) {
    let selectedLang;
    let selectedLangIndex;
    this.languagesDropdownItems.forEach((item, index) => {
      if (item.key === key) {
        item.isSelected = true;
        selectedLang = item;
        selectedLangIndex = index;
      } else {
        item.isSelected = false;
      }
    });
    this.languagesDropdownItems[selectedLangIndex] = selectedLang; // Due to javascript limitation, we need to force changes detection by reassigning object.
    switchLanguageAsync(key);
  }
  /**
   * Create the default logo if no logo is given
   */
  get logoPath() {
    return this.logo;
  }
  toggleMenu() {
    store.dispatch(MENU_VISIBLE, !store.getters.isMenuVisible);
  }
};
__decorate([Prop({
  default: require('./assets/logo_new.png')
})], Header.prototype, "logo", void 0);
Header = __decorate([Options({
  components: {
    WDropdown
  },
  methods: {
    isAuth,
    logoutUser
  },
  data() {
    return {
      loading: true,
      transitionBack: false,
      routeActive: true
    };
  },
  computed: {
    isMenuVisible() {
      return store.getters.isMenuVisible;
    }
  }
})], Header);
export default Header;