import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.reduce.js";
import { __decorate } from "tslib";
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Banner from "@/components/banner/Banner.vue";
import store from "@/store";
import { AUTH_LINK_AUTH_GENERATE, AUTH_LINK_WITH_AUTHITEM } from "@/store/actions/authLink";
let AuthLinkConnect = class AuthLinkConnect extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "authItemID", void 0);
    _defineProperty(this, "asc", void 0);
    _defineProperty(this, "token", void 0);
    _defineProperty(this, "originalPlatform", void 0);
    _defineProperty(this, "loading", true);
    _defineProperty(this, "errorMsg", '');
  }
  mounted() {
    this.loadPublicAuthLink();
  }
  get redirectMessage() {
    return this.originalPlatform ? 'Connection to ' + this.originalPlatform : '';
  }
  get authLink() {
    return Object.values(this.linkList)?.find(link => link.platform.url === this.originalPlatform);
  }
  /**
   * Get the list of other available platforms for the current user
   * format = "name1:url1,name2:url2,name3:url3..."
   */
  get otherPlatformsAvailable() {
    return Object.values(this.linkList).reduce((accumulator, link) => {
      if (link.platform.platformID !== this.authLink.platform.platformID) {
        accumulator.push(link.platform.name + ':' + link.platform.url);
      }
      return accumulator;
    }, []).toString();
  }
  get linkList() {
    return store.getters.authLinks;
  }
  loadPublicAuthLink() {
    // we load the list of cluster
    store.dispatch(AUTH_LINK_WITH_AUTHITEM, {
      authItemId: this.authItemID,
      token: this.token
    }).then(() => {
      this.goToPlatform();
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
  async goToPlatform() {
    // have to regenerate a token because we receive an enterprise suite token
    store.dispatch(AUTH_LINK_AUTH_GENERATE, {
      token: this.token,
      authLinkID: this.authLink.authLinkID
    }).then(tokenToSend => {
      const form = this.$refs.formPost;
      const token = this.$refs.tokenToSend;
      token.value = tokenToSend; // we don't use v-model for the token, to avoid rendering time
      form.submit();
    }).catch(err => {
      this.loading = false;
      this.errorMsg = err;
    });
  }
};
__decorate([Prop()], AuthLinkConnect.prototype, "authItemID", void 0);
__decorate([Prop()], AuthLinkConnect.prototype, "asc", void 0);
__decorate([Prop()], AuthLinkConnect.prototype, "token", void 0);
__decorate([Prop()], AuthLinkConnect.prototype, "originalPlatform", void 0);
AuthLinkConnect = __decorate([Options({
  components: {
    Banner: Banner
  }
})], AuthLinkConnect);
export default AuthLinkConnect;